<script>
import CMExamConfigValidator from "../../classes/CMExamConfigValidator"

import FormErrors from '@/util/form-errors'
import errorsMixin from '@/mixins/errors.mixin'
import CMExamConfig, { CM_EXAM_CONFIG } from '../../classes/CMExamConfig'
export default {
  name: 'ExamEdit',
  mixins: [errorsMixin],

  render() {
    return this.$scopedSlots.default({
      showing: this.showing,
      toggle: this.toggle,
      formErrors: this.formErrors,
      checkValidatorBeforeSend: this.checkValidatorBeforeSend,
      updateShowing: this.updateShowing,
      formData: this.formData,
      openSelected: this.openSelected,
      remove: this.remove
    })
  },

  inject: ['svc'],

  data:() => ({
    showing: false,
    processing: false,
    formErrors: new FormErrors(),
    formData: new CMExamConfig({
      [CM_EXAM_CONFIG.MAX_STUDENTS]: 7
    })
  }),

  methods: {
    toggle() {
      this.showing = !this.showing
    },

    updateShowing(val) {
      this.showing = val
    },

    openSelected(formData) {
      this.formData = formData
      this.showing = true
    },

    checkValidatorBeforeSend(payload) {
      this.formErrors = payload.validator
      if(!this.formErrors.isValid()) {
        this.$notify({text:'Invalid fields', type: 'error'})
        return
      }
      this.send(payload)
    },

    async send({settingID, data, courseID}) {
      try {
        this.processing = true
        let res = await this.svc.updateConfig({courseID: courseID, settingID, data})
        this.$emit('updated', res)
        this.$notify({text: 'Exam updated', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    },

    async remove({settingID, courseID}) {
      try {
        this.processing = true
        let res = await this.svc.removeConfig({courseID: courseID, settingID})
        this.$emit('deleted', settingID)
        this.$notify({text: 'Exam deleted', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    }
  }
}
</script>
